import React from 'react';
import './../scss/app.scss';

export default function LoadingSpin() {
  return (
    <div className="spinner-container">
      <div className="loading-spinner"></div>
    </div>
  );
}
