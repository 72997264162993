import './scss/app.scss';
import React, { useContext } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Sidebar from './components/Sidebar';
// import Navbar from './components/Navbar';
import { Box, Container, CssBaseline, Grid } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import PageContext from './context/page/PageContext';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
  useMatch,
  useResolvedPath,
} from 'react-router-dom';
import RequireAuth from './RequireAuth';
import RequirePublic from './RequirePublic';

import About from './pages/legal/About';
import Contact from './pages/legal/Contact';
import HomeAuth from './pages/auth/HomeAuth';
import Login from './pages/auth/Login';
import Register from './pages/auth/Register';
import ForgotPw from './pages/auth/ForgotPw';
import ResetPw from './pages/auth/ResetPw';
import Home from './pages/Home';
import Schedule from './pages/schedule/Schedule';
import Profile from './pages/Profile';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { alpha } from '@mui/material';
import ContainerLegal from './pages/legal/ContainerLegal';
import AuthContext from './context/auth/AuthContext';
import NotFound from './pages/NotFound';
import { useEffect } from 'react';
import CategoryCover from './pages/categories/category/CategoryCover';
import { useState } from 'react';
import CategoryPlayVideos from './pages/categories/category/CategoryPlayVideos';
import TempPassword from './pages/TempPassword';

import LoginAs from './pages/LoginAs';
import ChatPage from './pages/chat/ChatPage';
import ChatLogs from './pages/chat/ChatLogs';

function App() {
  const { loading, page } = useContext(PageContext);
  const { user } = useContext(AuthContext);

  const primaryColor =
    page && page.primary_color ? page.primary_color : '#0075FF';
  const primaryColorContrast =
    page && page.primary_contrast_color
      ? page.primary_contrast_color
      : '#fff';

  // const primaryColor = '#fff';
  // const primaryColorContrast = '#000';

  const theme = createTheme({
    palette: {
      primary: {
        main: primaryColor,
        contrastText: primaryColorContrast,
      },

      secondary: {
        main: '#ABABAB',
      },
      niceGreenButton: {
        main: '#00b23f',
        contrastText: '#fff',
        action: {
          hover: alpha('#00b23f', 0.8),
          active: alpha('#00b23f', 0.8),
        },
      },
      blackBg: {
        main: '#181818',
        contrastText: '#fff',
      },
      warning: {
        main: '#fe0085',
      },
      muted: {
        main: '#b3b3b3',
      },
      pink: {
        main: 'rgb(228, 64, 135)',
        contrastText: '#fff',
      },
      muted_light: {
        main: '#efefef',
        contrastText: '#818181',
      },
      dark: {
        main: '#000',
        contrastText: '#fff',
      },
      white: {
        main: '#fff',
        secondary: '#cccccc',
        contrastText: '#000',
      },
      gray: {
        main: '#dddddd',
        contrastText: '#000',
      },
      iconDarkDefault: {
        main: '#898989',
        contrastText: '#fff',
      },
      lightButton: {
        main: 'rgba(217,217,217,0.56)',
        contrastText: '#fff',
      },

      drawerBg: {
        main: '#111111',
      },
    },
    typography: {
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Roboto Condensed"',
        '"Lato"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      roboto: '"Roboto Condensed",sans-serif',
      lato: "'Lato', sans-serif",
      body1: {
        fontFamily: "'Lato', sans-serif",
        fontSize: { xs: '16px', md: '20px' },
        fontWeight: 400,
      },
      body2: {
        fontFamily: "'Lato', sans-serif",
        fontWeight: 400,
      },
      h1: {
        fontFamily: "'Roboto Condensed', sans-serif",
        fontWeight: 700,
        letterSpacing: '0.2px',
      },
      h2: {
        fontFamily: "'Roboto Condensed', sans-serif",
        fontWeight: 700,
        letterSpacing: '0.15px',
      },
      h3: {
        fontFamily: "'Roboto Condensed', sans-serif",
        fontWeight: 700,
        letterSpacing: '0.15px',
      },
      h4: {
        fontFamily: "'Roboto Condensed', sans-serif",
        fontWeight: 700,
        letterSpacing: '0.15px',
      },
      h5: {
        fontFamily: "'Roboto Condensed', sans-serif",
        fontWeight: 700,
        letterSpacing: '0.15px',
      },
      h6: {
        fontFamily: "'Roboto Condensed', sans-serif",
        fontWeight: 700,
        letterSpacing: '0.15px',
      },
    },
  });

  const replaceManifest = async () => {
    let installPromptBtn =
      document.getElementsByClassName('installPrompt');

    let pageUrl = window.location.href;

    //manifest - app details
    let manifest = {
      background_color: theme.palette.background.default
        ? theme.palette.background.default
        : '#fff',
      description: 'Keep Moving & Stay Healthy',
      display: 'standalone',
      prefer_related_applications: false,

      icons: [
        {
          src:
            page && page.favicon && page.favicon['512']
              ? page.favicon['512']
              : 'favicon-default-512.png',
          type: 'image/png',
          sizes: '512x512',
          purpose: 'any',
        },
        {
          src:
            page && page.favicon && page.favicon['512']
              ? page.favicon['512']
              : 'favicon-default-512.png',
          type: 'image/png',
          sizes: '512x512',
          purpose: 'maskable',
        },
        {
          src:
            page && page.favicon && page.favicon['192']
              ? page.favicon['192']
              : 'favicon-default-192.webp',
          type: 'image/webp',
          sizes: '192x192',
          purpose: 'any ',
        },
      ],
      name: page && page.name ? page.name : 'FitnessApp',
      short_name: page && page.name ? page.name : 'FitnessApp',
      start_url: pageUrl,
      id: '/',
      theme_color: primaryColor,
    };

    let content = encodeURIComponent(JSON.stringify(manifest));
    let urlManifest = 'data:application/manifest+json,' + content;
    let element = document.createElement('link');
    element.setAttribute('rel', 'manifest');
    element.setAttribute('href', urlManifest);

    document
      .getElementById('icon')
      .setAttribute(
        'href',
        page && page.favicon && page.favicon['512']
          ? page.favicon['512']
          : 'favicon-default-512.png'
      );
    document
      .getElementById('appleicon')
      .setAttribute(
        'href',
        page && page.favicon && page.favicon['512']
          ? page.favicon['512']
          : 'favicon-default-512.png'
      );

    let oldmanifest = document.getElementById('staticManifest');
    if (oldmanifest) oldmanifest.remove();
    document.querySelector('head').appendChild(element);

    if (
      installPromptBtn.length > 0 &&
      !window.matchMedia('(display-mode: standalone)').matches
    ) {
      let brandFav = document.getElementById('brand_favicon');
      let brandName = document.getElementById('brand_name');

      brandFav.setAttribute(
        'src',
        page.favicon
          ? page.favicon['192']
          : 'favicon-default-192.webp'
      );
      brandName.innerText = page.name;
      brandName.style.color = primaryColor;

      installPromptBtn[0].style.display = 'flex';
    }
  };
  useEffect(() => {
    if (page) {
      replaceManifest();
    }
  }, [page]);

  //pages where to hide sidebar$ dummybox
  const pagesWithoutSidebar = ['/workout'];

  //custom sidebar to hide it on specific pages
  const CustomSidebar = () => {
    let location = useLocation();
    const hide = hideIt(location.pathname);

    if (hide) {
      return false;
    }
    return <Sidebar />;
  };

  //custom Dubbybox for when the sidebar is visible
  function DummyBox() {
    //pages where to hide sidebar

    let location = useLocation();

    const hide = hideIt(location.pathname);

    if (hide) {
      return false;
    }
    return (
      <Box
        className="dummyBox"
        sx={{ height: { xs: '130px', md: '30px' } }}
      />
    );
  }

  //if location matches
  const hideIt = (path) => {
    let hide = false;

    for (const i in pagesWithoutSidebar) {
      if (path.match(pagesWithoutSidebar[i]) && user) {
        hide = true;
      }
    }
    return hide;
  };
  return (
    <ThemeProvider theme={theme}>
      <HelmetProvider>
        <ToastContainer />
        <CssBaseline />

        {!loading && page ? (
          <>
            <Helmet>
              <meta name="description" content={page.name} />
              <meta name="theme-color" content={primaryColor} />

              <title>{page.name}</title>
            </Helmet>
            <Router>
              <Container
                maxWidth={false}
                disableGutters={true}
                className="mainContainer"
                sx={{
                  minHeight: '100vh',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'stretch',
                  flexGrow: '1',
                }}
              >
                <Grid
                  container
                  sx={{
                    flexWrap: { xs: 'wrap', md: 'nowrap' },
                    alignItems: 'stretch',
                    flexGrow: '1',
                    width: '100%',
                  }}
                >
                  {user ? (
                    user.verified === true ? (
                      <>
                        <CustomSidebar />
                      </>
                    ) : (
                      ''
                    )
                  ) : (
                    ''
                  )}
                  <Grid
                    item
                    sx={{
                      flexDirection: { xs: 'row', md: 'column' },
                      justifyContent: 'stretch',
                      flexGrow: '1',
                      order: { xs: '1', md: '2' },
                      width: '100%',
                      overflow: 'hidden',
                    }}
                  >
                    <Routes>
                      <Route path="/" element={<Home />} />
                      {/*<Route path="/" element={user ? (user.tempPassword === false ? <><Home /></> : <TempPassword/>) : <TempPassword />} />*/}

                      {/* PUBLIC ROUTES  */}
                      <Route element={<RequirePublic />}>
                        <Route path="login" element={<Login />} />
                        <Route
                          path="homeauth"
                          element={<HomeAuth />}
                        />

                        {page.access_register_page && (
                          <Route
                            path="sign-up"
                            element={<Register />}
                          />
                        )}
                        <Route path="forgot" element={<ForgotPw />} />
                        {page.privacy_policy === null &&
                        page.terms_and_conditions === null ? (
                          ''
                        ) : (
                          <Route
                            path="/legal/*"
                            element={<ContainerLegal />}
                          />
                        )}
                        <Route
                          path="/reset-password"
                          element={<ResetPw />}
                        />
                      </Route>
                      {/* PRIVATE ROUTES  */}
                      <Route element={<RequireAuth />}>
                        <Route
                          path="workout/:id"
                          element={<CategoryCover />}
                        />
                        <Route
                          path="workout/:cat_id/videos/"
                          element={<CategoryPlayVideos />}
                        />
                        <Route
                          path="/schedule"
                          element={<Schedule />}
                        />
                        {/* <Route path='/foryou' element={<ForYou />} /> */}
                        <Route path="profile" element={<Profile />} />
                        <Route
                          path="chat/logs"
                          element={<ChatLogs />}
                        />
                        <Route path="chat" element={<ChatPage />} />
                      </Route>

                      <Route
                        path="/login-as-client"
                        element={<LoginAs />}
                      ></Route>

                      {/* GLOBAL ROUTES  */}
                      <Route path="/about" element={<About />} />
                      {page.access_contact_us_page && (
                        <Route
                          path="/contact"
                          element={<Contact />}
                        />
                      )}
                      <Route
                        path="*"
                        exact={true}
                        element={<NotFound />}
                      />
                    </Routes>
                    <DummyBox />
                  </Grid>
                </Grid>
              </Container>

              {/* <Sidebar /> */}
            </Router>
          </>
        ) : (
          <>
            {!page && !loading ? (
              'There was an error with loading the page details :('
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'center',
                  minHeight: '100vh',
                  alignItems: 'center',
                }}
              >
                <CircularProgress />
              </Box>
            )}
          </>
        )}
      </HelmetProvider>
    </ThemeProvider>
  );
}

export default App;
