import { Avatar, Grid, Paper, Typography } from '@mui/material';
import avatar from '../../../assets/icons/avatar.jpg';

//setup options to render the message from the user or from the assistant

export default function UserChatMessage({ messages }) {
  return (
    <>
      <Paper elevation={3} />
      <Grid
        item
        container
        sx={{
          borderRadius: 2,
          paddingY: 2,
          backgroundColor:
            messages.sender === 'user' ? 'smokewhite' : '#F0F0F0',
          boxShadow: messages.sender === 'assistant' ? 1 : undefined,
        }}
        gap={2}
        wrap="nowrap"
        alignItems="center"
      >
        <Grid item>
          {messages.sender === 'user' &&
          messages.conversation_id === '' ? (
            ''
          ) : messages.sender === 'user' &&
            messages.conversation_id !== '' ? (
            <Avatar
              sx={{
                backgroundColor: '#0d0d0d',
                borderRadius: '8px',
                marginLeft: 1,
              }}
            >
              Me
            </Avatar>
          ) : (
            <Avatar
              alt="Angela"
              src={avatar}
              sx={{ borderRadius: '8px', marginLeft: 1 }}
            ></Avatar>
          )}
        </Grid>
        <Grid item>
          <Typography
            variant="h6"
            sx={{ fontWeight: 600, lineHeight: 1.2 }}
          >
            {messages.message}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
}
