import GreetingsHeader from '../../components/GreetingsHeader';
import LoadingSpin from '../../components/LoadingSpin';

import { Box, Grid } from '@mui/material';
import ChatBot from './components/ChatBot';

function ChatPage() {
  return (
    <>
      <Grid container>
        <Grid
          item
          // maxWidth={null}
          sx={{
            width: '100%',
            px: { xs: '27px', md: '50px' },
            marginLeft: 'unset',
          }}
        >
          <Grid
            item
            sx={{
              color: '#181818',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-end',
              justifyContent: { md: 'space-between' },
              fontSize: '.9em',
              position: 'relative',
              px: 0,
              pt: 3,
              pb: '24px',
            }}
          >
            <GreetingsHeader />
            <LoadingSpin />
          </Grid>
        </Grid>

        <Grid container>
          <ChatBot />
        </Grid>
      </Grid>
    </>
  );
}

export default ChatPage;
