import {
  Badge,
  Button,
  FormControl,
  Grid,
  ThemeProvider,
  Typography,
  createTheme,
} from '@mui/material';
import React, { useState } from 'react';
import UserChatMessage from './UserChatMessage';

import {
  Box,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Chip,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  Modal,
  TextField,
} from '@mui/material';
import {
  Calculate,
  Explore,
  InterpreterMode,
  RestartAlt,
  TheaterComedy,
  Translate,
} from '@mui/icons-material';
import SendIcon from '@mui/icons-material/Send';
import avatar from '../../../assets/icons/avatar.jpg';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';

import axios from 'axios';
import { useContext, useEffect } from 'react';
import AuthContext from '../../../context/auth/AuthContext';
import { Link as RouterLink } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '75%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 20,
};

const identifier = window.location.hostname;

const ChatBot = () => {
  const { apiUrl, token } = useContext(AuthContext);
  const { user } = useContext(AuthContext);

  const [anchorEl, setAnchorEl] = useState(null);

  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  //get all assistants from the database

  useEffect(() => {
    axios
      .get(`${apiUrl}/open-ai/assistants`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        //create an array of categories
        const categories = response.data.map((assistant) => {
          return assistant.category;
        });
        setCategories(categories);
        //create an array only with assistants that match the selected category
        const individualAssistant = response.data;

        setIndividualAssistant(individualAssistant);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  //setup all the paramteres required for the axios call to messages endpoint

  const [categories, setCategories] = useState([]);
  const [assistantCategory, setAssistantCategory] = useState('');
  const handleAssistantCategory = (category) => {
    setAssistantCategory(category);
  };
  const [individualAssistant, setIndividualAssistant] = useState([]);

  const [selectedAssistant, setSelectedAssistant] = useState('');

  const handleSelectedAssistant = (assistant) => {
    setSelectedAssistant(assistant);
    handleCloseModal();
    clearChatLogs();
  };

  const categoryAssistants =
    individualAssistant.length > 0
      ? individualAssistant
          .filter(
            (assistant) => assistant.category === assistantCategory
          )
          .map((assistant) => assistant.assistants)
      : [];

  //setup the icons for each category
  const imageUrls = [
    'https://example.com/image1.jpg',
    'https://example.com/image2.jpg',
    'https://example.com/image3.jpg',
    // Add more image URLs to the list as needed
  ];
  const [randomImageUrl, setRandomImageUrl] = useState('');

  const fetchRandomImageFromList = () => {
    const randomIndex = Math.floor(Math.random() * imageUrls.length);
    const randomUrl = imageUrls[randomIndex];
    setRandomImageUrl(randomUrl);
  };
  const getIcon = (category) => {
    switch (category) {
      case 'Fitness Trainer':
        return <FitnessCenterIcon sx={{ color: '#bfbff2' }} />;
      case 'Math Teacher':
        return <Calculate sx={{ color: '#bfbff2' }} />;
      case 'Interviewer':
        return <InterpreterMode sx={{ color: '#bfbff2' }} />;
      case 'English Translator':
        return <Translate sx={{ color: '#bfbff2' }} />;
      case 'Travel Guide':
        return <Explore sx={{ color: '#bfbff2' }} />;
      case 'Stand-up Comedian':
        return <TheaterComedy sx={{ color: '#bfbff2' }} />;
      default:
        return null;
    }
  };

  //setup the functionality of the ChatBOx

  const [userInput, setUserInput] = useState('');
  const [conversationID, setConversationID] = useState('');
  const [chatLog, setChatLog] = useState([]);

  //clear chatlogs
  const clearChatLogs = () => {
    setChatLog([]);
  };
  const [isLoading, setIsLoading] = useState(false);

  // function to handle and update the chatlog

  async function handleSubmit() {
    // e.preventDefault();
    setIsLoading(true);
    let updatedChatLog = [
      ...chatLog,
      {
        assistant_category: assistantCategory,
        assistant_name: selectedAssistant,
        conversation_id: conversationID,
        message: `${userInput}`,
        client_domain: identifier,
        sender: 'user',
      },
    ];

    setUserInput('');
    setChatLog(updatedChatLog);

    const allMessages = updatedChatLog
      .map((message) => message.message)
      .join('\n');

    //axios call to the messages endpoint, with all the required parameters
    const response = async () => {
      await axios
        .post(
          `${apiUrl}/open-ai/message`,
          {
            assistant_category: assistantCategory,
            assistant_name: selectedAssistant,
            conversation_id: conversationID,
            message: allMessages,
            client_domain: identifier,
            sender: '',
          },
          {
            params: {},
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          setConversationID(response.data.conversation_id);
          setChatLog([
            ...updatedChatLog,
            {
              assistant_category: assistantCategory,
              assistant_name: selectedAssistant,
              conversation_id: conversationID,
              message: `${response.data.message}`,
              client_domain: identifier,
              sender: 'assistant',
            },
          ]);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    };
    response();
  }
  console.log(chatLog);
  console.log(userInput);

  useEffect(() => {
    if (selectedAssistant) {
      handleSubmit(selectedAssistant);
    }
  }, [selectedAssistant]);

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  };

  //setup the loading state
  const [loading, setLoading] = useState(false);
  //setup loading functionality
  useEffect(() => {
    if (loading) {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  }, [loading]);
  console.log(userInput.length);
  //setup loading component
  const Loading = () => {
    return (
      <Grid
        item
        container
        justifyContent={'center'}
        alignItems={'center'}
        sx={{ height: '100%' }}
      ></Grid>
    );
  };
  if (loading) {
    return <Loading />;
  }

  //ChatBox UI
  return (
    <Grid
      container
      maxWidth={null}
      sx={{
        px: { xs: '27px', md: '50px' },
        marginLeft: 'unset',
      }}
    >
      <Grid
        item
        container
        sx={{
          minHeight: '45vh',
          maxHeight: '45vh',
          overflowY:
            selectedAssistant.length !== 0 ? 'scroll' : 'visible',
        }}
      >
        <Grid item>
          {chatLog.map((messages, index) => (
            <UserChatMessage key={index} messages={messages} />
          ))}
        </Grid>
      </Grid>

      <Grid
        item
        container
        justifyContent={'space-between'}
        maxWidth={null}
        alignItems={'center'}
        sx={{
          px: { xs: '27px', md: '50px' },
          marginLeft: 'unset',
        }}
      >
        {/* <Grid item>
          <Typography variant="body1" fontWeight={'600'}>
            Include latest Google options
          </Typography>
        </Grid> */}
        {chatLog.length !== 0 ? (
          <Grid
            item
            container
            width={'auto'}
            alignItems={'center'}
            color={'#bfbff2'}
          >
            <Grid item>
              <IconButton
                onClick={clearChatLogs}
                sx={{ color: '#bfbff2' }}
              >
                <RestartAlt />
              </IconButton>
            </Grid>
            <Grid item>
              <Typography variant="body1" fontWeight={'bold'}>
                Reset Chat
              </Typography>
            </Grid>
          </Grid>
        ) : null}
        <Grid item container width={'auto'} alignItems={'center'}>
          <Grid item>
            <Chip
              label="Today's limit"
              sx={{
                borderRadius: '8px',
                fontWeight: 'bold',
                fontSize: '14px',
                color: 'grey',
                marginRight: '5px',
              }}
            ></Chip>
          </Grid>
          <Grid item>
            <Badge badgeContent={23} color="secondary"></Badge>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        container
        sx={{
          marginX: 7,
          border: '1px solid lightgrey',
          borderRadius: '11px',
          marginTop: 1,
          bgcolor: '	 #e6e6fa',
        }}
      >
        <Grid item container>
          <Grid item container alignContent={'center'} paddingX={2}>
            <Typography variant="body2" alignSelf={'center'}>
              Current personality :
            </Typography>
            <Grid item>
              <Button
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
              >
                <Chip
                  label="Select Category"
                  color="white"
                  sx={{ borderRadius: '8px' }}
                ></Chip>
              </Button>

              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                {categories.map((category) => (
                  <MenuItem
                    key={category}
                    onClick={() => {
                      handleAssistantCategory(category);
                      handleOpenModal();
                      handleClose();
                      setLoading(true);
                      // handleAsssistant();
                    }}
                  >
                    <Grid container gap={1}>
                      <Grid item>{getIcon(category)}</Grid>
                      <Grid item>{category}</Grid>
                    </Grid>
                  </MenuItem>
                ))}
              </Menu>

              <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Grid
                  sx={style}
                  item
                  container
                  flexDirection={'row'}
                  justifyContent={'center'}
                  gap={4}
                >
                  {categoryAssistants.length > 0 ? (
                    categoryAssistants[0].map((assistant, index) => (
                      <Card
                        key={index}
                        sx={{
                          minWidth: '180px',
                          maxWidth: '180px',
                        }}
                      >
                        <CardActionArea>
                          <CardMedia
                            component="img"
                            height="180"
                            image={avatar}
                            alt="Avatar"
                          />
                          <CardContent
                            sx={{
                              textAlign: 'center',
                            }}
                          >
                            <Typography
                              gutterBottom
                              variant="h6"
                              color="primary"
                              component="div"
                            >
                              {assistant}
                            </Typography>
                            <Typography
                              variant="body2"
                              color="text.secondary"
                            >
                              {assistantCategory}
                            </Typography>
                          </CardContent>
                        </CardActionArea>
                        <CardActions>
                          <Button
                            id={index}
                            sx={{ textTransform: 'none' }}
                            variant="contained"
                            size="small"
                            color="primary"
                            fullWidth
                            // onClick={handleSelectedAssistant(
                            //   assistant
                            // )}
                            onClick={() => {
                              handleSelectedAssistant(assistant);

                              //  handleCloseModal();
                              //  clearChatLogs();
                            }}
                          >
                            Chat Now
                          </Button>
                        </CardActions>
                      </Card>
                    ))
                  ) : (
                    <Typography variant="body1" fontWeight={'600'}>
                      No assistants available
                    </Typography>
                  )}
                </Grid>
              </Modal>
            </Grid>
            <Grid item sx={{ marginLeft: 'auto' }}>
              <Button component={RouterLink} to="/chat/logs">
                <Chip
                  label="  Chat History"
                  color="white"
                  sx={{ borderRadius: '8px', cursor: 'pointer' }}
                ></Chip>
              </Button>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          container
          bgcolor="whitesmoke"
          sx={{ borderRadius: '10px' }}
        >
          <Box
            sx={{
              '& > :not(style)': { m: 1 },
              width: '100%',
            }}
          >
            <TextField
              onKeyDown={handleKeyPress}
              id="input-with-icon-textfield"
              disabled={chatLog.length === 0 ? true : false}
              label={
                selectedAssistant.length === 0
                  ? 'Please select Category to start...'
                  : 'Please write your message here...'
              }
              value={userInput}
              onChange={(e) => setUserInput(e.target.value)}
              sx={{ width: '100%' }}
              InputProps={{
                disableUnderline: true,
                endAdornment: (
                  <InputAdornment position="start">
                    <IconButton
                      sx={{
                        '&:hover': {
                          backgroundColor: 'transparent',
                        },
                      }}
                      onClick={handleSubmit}
                      disabled={chatLog.length === 0 ? true : false}
                    >
                      {isLoading ? (
                        <CircularProgress
                          color="inherit"
                          size="30px"
                        />
                      ) : (
                        <SendIcon
                          sx={{
                            color: '#bfbff2',
                          }}
                        />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              variant="standard"
            />
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ChatBot;
