import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MailIcon from '@mui/icons-material/Mail';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Button, Grid, Paper } from '@mui/material';
import axios from 'axios';
import { useContext } from 'react';
import AuthContext from '../../context/auth/AuthContext';
import { useState } from 'react';
import { useEffect } from 'react';
import { Add, ImportExport, ViewSidebar } from '@mui/icons-material';
import UserChatMessage from './components/UserChatMessage';
import { Link as RouterLink } from 'react-router-dom';

import {
  Calculate,
  Explore,
  InterpreterMode,
  TheaterComedy,
  Translate,
} from '@mui/icons-material';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';

const drawerWidth = 240;
const identifier = window.location.hostname;

function ResponsiveDrawer(props) {
  const { apiUrl, token } = useContext(AuthContext);
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  useEffect(() => {
    const getUserLogs = async () => {
      try {
        const res = await axios.get(`${apiUrl}/open-ai/user-logs`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            client_domain: identifier,
          },
        });

        //destructure the response object and store the user logs in state
        const { data } = res;
        setUserLogs(data);
      } catch (error) {}
    };
    getUserLogs();
  }, []);

  //use the useState hook to store the user logs in state
  const [userLogs, setUserLogs] = useState({});

  //extract the keys from the user logs object
  const keys = Object.keys(userLogs);

  const values = Object.values(userLogs);

  //create a new array of objects with the keys and values extracted above and store them in a new array of objects
  const userLogsArray = keys.map((key, index) => {
    return {
      key: key,
      value: values[index],
    };
  });

  //make deep copy of the userLogsArray
  const userLogsArrayCopy = JSON.parse(JSON.stringify(userLogsArray));

  //useState for selectedLog
  const [selectedLog, setSelectedLog] = useState({});

  //render the user log clicked
  const renderUserLog = (e) => {
    //get the id of the button clicked
    const id = e.currentTarget.id;

    //filter the user logs array to get the user log clicked
    const selectedLog = userLogsArray.filter(
      (userlog) => userlog.key === id
    );
    setSelectedLog(selectedLog);
  };

  const drawer = (
    <div>
      {/* <Toolbar /> */}
      <Grid
        container
        marginX={2}
        marginY={1}
        gap={1}
        // justifyContent={'space-around'}
      >
        <Grid item>
          <Button
            component={RouterLink}
            to="/chat"
            variant="outlined"
            color="primary"
            sx={{
              textTransform: 'none',
              minWidth: 160,
              justifyContent: 'flex-start',
            }}
          >
            <Add />
            New Chat
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="outlined"
            color="primary"
            sx={{
              textTransform: 'none',
              minWidth: '32px',
              paddingX: '5px',
            }}
          >
            <ViewSidebar />
          </Button>
        </Grid>
      </Grid>

      <List>
        {userLogsArrayCopy.map(
          (logs) =>
            logs.value.splice(0, 1) &&
            logs.value.splice(1) &&
            logs.value.map((log, index) => {
              return (
                <ListItem
                  key={index}
                  disablePadding
                  onClick={renderUserLog}
                  id={logs.key}
                >
                  <ListItemButton>
                    <ListItemIcon sx={{ justifyContent: 'center' }}>
                      {log.category === 'Fitness Trainer' ? (
                        <FitnessCenterIcon color="primary" />
                      ) : // sx={{ color: '#bfbff2' }}

                      log.category === 'Math Teacher' ? (
                        <Calculate color="primary" />
                      ) : log.category === 'Interviewer' ? (
                        <InterpreterMode color="primary" />
                      ) : log.category === 'English Translator' ? (
                        <Translate color="primary" />
                      ) : log.category === 'Travel Guide' ? (
                        <Explore color="primary" />
                      ) : log.category === 'Stand-up Comedian' ? (
                        <TheaterComedy color="primary" />
                      ) : (
                        <ImportExport color="primary" />
                      )}
                    </ListItemIcon>
                    <ListItemText
                      // primary={logs.key}
                      primary={
                        log.content
                          .split('\n')
                          .splice(1)
                          .slice(0, 1)
                          //show only the first 20 characters of the message
                          .toString()
                          .substring(0, 15) + '...'
                      }
                    />
                  </ListItemButton>
                </ListItem>
              );
            })
        )}
      </List>

      <Divider />

      <List>
        {['Demo', 'Demo2'].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton>
              <ListItemIcon>
                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            Chat History
          </Typography>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Toolbar />
        <Grid container>
          {selectedLog.length > 0 ? (
            selectedLog[0].value.map((log) =>
              log.role === 'user' && log.index === 1 ? (
                <UserChatMessage
                  key={log.index}
                  messages={{
                    message: log.content
                      .split('\n')
                      .splice(1)
                      .slice(0, 1),
                    sender: log.role,
                    conversation_id: log.conversation_id,
                  }}
                />
              ) : log.role === 'user' && log.index !== 1 ? (
                <UserChatMessage
                  key={log.index}
                  messages={{
                    message: log.content.split('\n').slice(-1),
                    sender: log.role,
                    conversation_id: log.conversation_id,
                  }}
                />
              ) : log.role === 'assistant' ? (
                <UserChatMessage
                  key={log.index}
                  messages={{
                    message: log.content,
                    sender: log.role,
                    conversation_id: log.conversation_id,
                  }}
                />
              ) : null
            )
          ) : (
            <Typography variant="h6" noWrap component="div">
              Select a chat to view
            </Typography>
          )}
        </Grid>
      </Box>
    </Box>
  );
}

ResponsiveDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default ResponsiveDrawer;
